.alert-message {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 8px;
    margin: 1rem 0;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    height: 4rem;
}

.alert-message.info {
    background-color: rgba(33, 150, 243, 0.1);
    border-color: rgba(33, 150, 243, 0.3);
}

.alert-message.warning {
    background-color: rgba(255, 152, 0, 0.1);
    border-color: rgba(255, 152, 0, 0.3);
}

.alert-message.success {
    background-color: rgba(76, 175, 80, 0.1);
    border-color: rgba(76, 175, 80, 0.3);
}

.alert-message .alert-icon {
    font-size: 1.5rem;
    margin-right: 1rem;
}

.alert-message p {
    margin: 0;
    color: #fff;
    font-size: 1rem;
}

.alert-message.info .alert-icon {
    color: #2196f3;
}

.alert-message.warning .alert-icon {
    color: #ff9800;
}

.alert-message.success .alert-icon {
    color: #4caf50;
} 