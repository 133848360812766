.configTitle {
    color: #000;
    flex-basis: 10%;
    margin-bottom: 1rem !important;
}
.configBtn{
    width: 100%;
    height: 10vh;
    background: rgba(255, 255, 255, 0.17);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
    backdrop-filter: blur(10.5px);
    -webkit-backdrop-filter: blur(10.5px);
    overflow: hidden;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
}
@media screen and (max-width: 575px) {
    .configBtn::after{
        content: '';
        position: absolute;
        left: -5%;
        top: -55%;
        right: 0;
        z-index: -1;
        background-color: #ffffff12;;
        height: 100%;
        border-radius: 20px 20px 0 0;
        transform: rotate(-15deg);
        width: 120%;
    }
  }
  @media screen and (min-width: 575px) {
    .configBtn::after{
        content: '';
        position: absolute;
        left: -5%;
        top: 0;
        right: 0;
        z-index: -1;
        background-color: #ffffff12;
        height: 50%;
        border-radius: 20px 20px 0 0;
    }
  }

#configCont{
    padding: 0 5vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.configApp {
    /* background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */
    background: linear-gradient(205deg, #0b6623 46%, #ff4d4d 80%);
    background-size: 300% 300%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.config-panel {
    background: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 2rem;
}

.config-section {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
}

.config-section:last-child {
    border-bottom: none;
}

.button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.configBtn.primary {
    background-color: #007bff;
    color: white;
}
.configBtn.info {
    background-color: #2790ff;
    color: white;
}

.configBtn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.winners-list {
    margin-top: 2rem;
}

.award-group {
    margin-bottom: 2rem;
    background: #f8f9fa;
    padding: 1rem;
    border-radius: 8px;
}

.award-group h4 {
    color: #495057;
    margin-bottom: 1rem;
}

.winner-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.configBtn.accept {
    background-color: #28a745;
}

.configBtn.reject {
    background-color: #dc3545;
}

.badge {
    font-size: 0.9rem;
    padding: 0.5rem;
}

.configBtn.danger {
    background-color: #dc3545;
    color: white;
}

.auto-spinning-indicator {
    display: inline-block;
    margin-left: 1rem;
    color: #007bff;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

.form-check {
    display: flex;
    align-items: center;
    margin: 0 1rem;
}

.form-check-input {
    margin-right: 0.5rem;
    cursor: pointer;
}

.form-check-label {
    color: white;
    margin-bottom: 0;
    cursor: pointer;
}

.configBtn.secondary {
    background-color: #6c757d;
    color: white;
}

.configBtn.secondary:hover {
    background-color: #5a6268;
}
.container {
    padding: 5vh 0;
}
.winners-section {
    margin: 20px 0;
}

.winners-header, .award-header {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #eee;
    transition: background-color 0.3s ease;
}

.winners-header:hover, .award-header:hover {
    background-color: rgba(0,0,0,0.02);
}

.winners-header h3, .award-header h4 {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.toggle-icon {
    transition: all 0.3s ease;
    opacity: 0.5;
    transform: rotate(0deg);
}

.toggle-icon.active {
    opacity: 1;
    color: #c02a45;
    transform: rotate(180deg);
}

.winners-content, .award-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    opacity: 0;
}

.winners-content.expanded {
    max-height: 2000px; /* Valor alto para asegurar que todo el contenido sea visible */
    opacity: 1;
}

.award-content.expanded {
    max-height: 1000px;
    opacity: 1;
}

.award-group {
    margin: 10px 0;
    border: 1px solid #eee;
    border-radius: 4px;
}

.award-content {
    padding: 0 15px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Estilos modernos para los filtros de Tabulator */
.tabulator-header-filter input:not([type="checkbox"]) {
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 14px;
    width: 100%;
    background-color: #f8fafc;
    transition: all 0.3s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.tabulator-header-filter input:focus {
    outline: none;
    border-color: #c02a45;
    box-shadow: 0 0 0 3px rgba(192, 42, 69, 0.1);
    background-color: #fff;
}

.tabulator-header-filter input::placeholder {
    color: #94a3b8;
}

/* Estilo para el selector de estado (tickCross) */
.tabulator-header-filter select {
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 14px;
    width: 100%;
    background-color: #f8fafc;
    transition: all 0.3s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 8px center;
    background-size: 16px;
    padding-right: 32px;
}

.tabulator-header-filter select:focus {
    outline: none;
    border-color: #c02a45;
    box-shadow: 0 0 0 3px rgba(192, 42, 69, 0.1);
    background-color: #fff;
}

/* Ajustes adicionales para mejorar la apariencia general */
.tabulator {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.tabulator-header {
    background-color: #f8fafc !important;
    border-bottom: 1px solid #e2e8f0;
}

.tabulator-header .tabulator-col {
    background-color: transparent;
    border-right: 1px solid #e2e8f0;
}

.tabulator-row {
    border-bottom: 1px solid #e2e8f0;
}

.tabulator-row:hover {
    background-color: #f1f5f9 !important;
}

/* Estilos para la paginación */
.tabulator-footer {
    background-color: #f8fafc;
    border-top: 1px solid #e2e8f0;
    padding: 8px;
}

.tabulator-paginator {
    font-size: 14px;
}

.tabulator-page {
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    padding: 6px 12px;
    margin: 0 2px;
    background-color: #fff;
    color: #475569;
    transition: all 0.3s ease;
}

.tabulator-page:hover {
    background-color: #f1f5f9;
    border-color: #cbd5e1;
}

.tabulator-page.active {
    background-color: #c02a45;
    color: #fff;
    border-color: #c02a45;
}

/* Estilo para los grupos */
.tabulator-row.tabulator-group {
    background-color: #f8fafc;
    border-bottom: 2px solid #e2e8f0;
    color: #475569;
    font-weight: 600;
}

.tabulator-row.tabulator-group:hover {
    background-color: #f1f5f9 !important;
}