@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');
.App {
  text-align: center;
  /* background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%); */
  /* Degradado navideño: verde y rojo predominantes */
  /* background: linear-gradient(135deg, #0b6623, #0b6623 40%, #ff0000 60%, #ff0000);
  background-size: 200% 200%;
  animation: softGradient 12s ease infinite; Animación más lenta */
  background: linear-gradient(205deg, #0b6623 46%, #ff4d4d 80%);
  background-size: 300% 300%;
  height: 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  font-family: 'Roboto Mono', monospace;
}
@media screen and (min-width: 980px) {
  .d-980-show{
    display: inline-block !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
/* Animación más calmada */
@keyframes softGradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
@keyframes subtleGradient {
  0% { background-position: 0% 50%; }
  25% { background-position: 10% 55%; }
  50% { background-position: 20% 50%; }
  75% { background-position: 15% 45%; }
  100% { background-position: 0% 50%; }
}
#partyName{
  font-family: 'Lobster', cursive;
  color: #fff;
  font-size: 2.7vw;
}
.App-header{
  height: 11vh;
  padding: 0 20vw;
  border-bottom: #fff solid 2px;
  
}
.shapeCont {
  position: fixed;
  bottom: 0;
  left: -2%;
}
.bgShape{
  width: 100vw;
  height: auto;
  position: absolute;
  top: -2%;
}
.myIconB {
  margin-left: 1rem;
  color: #ffb200;
  margin-right: 1rem;
}
.headerCont{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* #appLogo{
  width: 300px;
} */
.myCont{
  padding: 0 3vw;
}
.bg-top-shape{
  position: absolute;
  left: 0;
  text-align: center;
  top: -2%;
}
.footer{
  height: 15vh;
  position: fixed;
  /* background-color: #2d0865; */
  bottom: 5vh;
  width: 100vw;
}
#allRewardsWidget{
  z-index: 10;
}
.mainWidgets{
  /*background-image: -webkit-linear-gradient(90deg, #4c3cc6 0%, #5a4bcc 100%);*/
  /* border: solid 1px #fff; */
  height: 55vh;
  position: relative;
  border-radius: 10px;
  /* box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Leve sombra */
  background-color: rgba(255, 255, 255, 0.1); /* Transparente con un leve brillo */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Borde sutil */
}
#root > div > main > div > div > div.col-sm-8 > div > div.widgetBody > div:nth-child(2) {
  height: 30vh;
  width: 30vh;
  position: absolute;
  top: 20%;
  left: 31%;
}
.mainWidgetsCont{
  margin-top: 10vh;
  justify-content: space-between; 
}
h1 {
  margin: 0 !important;
}
.ticker__field {
  width: 100%;
  position: relative !important;
}
.ticker__element h1{
  color: #fff;
}
.ticker__field__text {
  display: flex;
  gap: 30px;
  
}
.ticker {
  height: 15vh !important;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 30px;
}
/* .ticker__element{
  left: 100px !important;
  top: calc(50% - 2rem) !important;
  display: flex;
} */
.amount {
  margin-left: 5px;
  color: #c02a45;
}
.widgetHeader {
  display: flex;
  padding: 1rem 2rem;
  color: #fff;
  flex-direction: column;
  border-bottom: solid 1px #fff;
}
.widgetHeader h3 {
  margin-bottom: 13px;
}
.userListItem{
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.listItemTop{
  display: flex;
  justify-content: space-between;
}
.listItemTop span{
  font-weight: bold;
  display: flex;
  align-items: center;
}
.listItemTop h4{
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}
.listItemBottom > h5 {
  color: #fff;
  text-align: left;
}
.myBadget {
  background-color: #ff3030;
  padding: 2px 10px;
  border-radius: 10px;
  font-weight: bold;
  color: #fff;
}
.listItemBottom .amount {
  color: #fff;
}
.widgetBody{
  padding: 1rem 2rem;
  color: #fff;
  text-align: left;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  flex-direction: column;
}

.myIcon{
  margin-right: 5px;
  color: #ffb200;

}
.ticker__element > h1 {
  white-space: nowrap;
}
.myBackdrop {
  position: fixed;
  opacity: 0;
  display: none;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;

}
.myBackdrop.show {
  pointer-events: none;
  display: block;
  opacity: .5;
}
.winnerRay{
  animation: rotateWinnerRay 20s infinite linear;
  z-index: 1041;
  display: none;
  position: fixed;
  top: 50%;
  right: 7%;
}
.winnerRay.show {
  display: block;
}
.winnerShadow{
  z-index: 1041;
  position: fixed;
  top: 15%;
  right: 20%;
}
.widgetHeader > h2 {
  color: #fff;
}
.widgetHeader .amount {
  color: #fff;
}
#winnerScreen {
  color: #fff;
}
#winnerScreen > h2 {
  font-size: 3rem;  
  color: #fff;
  font-weight: bold;
}
#winnerScreen > h1{
  font-size: 5rem;
  font-weight: bold;
}
#winnerScreen > p {
  font-size: 4rem;
}
#winnerScreenCont {
  position: relative;
  z-index: 1042;
  transform: translate(0, -160%);
}
.mainWidgetA {
  z-index: 10;
}
.mainWidgetA.expanded {
  width: 94vw !important;
  height: 74vh !important;
}
.widgetHeader h2.expanded {
  font-size: 3.5vw !important;
}
#root > div > main > div > div > div.col-sm-8 > div > div.widgetBody > div.expanded {
  width: 45vh !important;
  height: 45vh !important;
}
.d3Cont:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 0;
  background-color: #ffffff1c;
  height: 50%;
  border-radius: 20px 20px 0 0;
}
.widgetBodyB{
  padding: 1rem 2rem;
  color: #fff;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  top: 0;
  height: 80%;
  justify-content: center;
}
#root > div > main > div > div > div.col-sm-4 > div > div.widgetBodyB > div{
  height: 20vh;
}
div.d3Cont {
  background: rgba(255, 255, 255, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  height: 15vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
/* .mainWidgets.mainWidgetA:after, .mainWidgets.mainWidgetB:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background-color: #ffffff0a;
  height: 50%;
  border-radius: 20px 20px 0 0;
} */
.attendantList {
  transition: all 10s cubic-bezier(0, 0, 0.1, 1) 0s;
  height: max-content;
  position: absolute;
  bottom: 0;
  width: 100%;
  perspective: 1000px;
  transform-style: preserve-3d;
}
.attendantList h1 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  max-width: 100%;
  display: block;
  width: 100%;
  text-align: center;
  font-weight: bold;
  height: 15vh;
  line-height: 15vh;
}
.attendantList h1.expanded {
  font-size: 4.5vw;
}
#confetti-canvas {
  position: absolute;
    top: 0;
}
.ticker__element {
  display: flex;
  width: -webkit-fill-available;
}
.awardItem{
  margin-right: 1vw;
  flex-direction: row;
  padding: 30px 30px;
  display: flex;
  align-items: center;
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  height: 15vh;
  position: relative;
  border-radius: 30px;
}

/*Animations section*/
.animateExpandWidgetA {
  animation: expandWidgetA 2s infinite;
}
.animateExpandRoulette {
  animation: expandRoulette 2s infinite;
}.animateExpandFontSize {
  animation: expandFontSize 2s infinite;
}
.animateExpandFontSizeB {
  animation: expandFontSizeB 2s infinite;
  animation-timing-function: ease-in-out; 
}
@keyframes rotateWinnerRay {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
}
100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
}
}
@keyframes expandWidgetA {
  0% {
    width: 55vw;
    height: 55vh;
}
100% {
  width: 94vw;
  height: 74vh;
}
}
@keyframes expandRoulette {
  0% {
    width: 30vh;
    height: 30vh;
}
100% {
  width: 45vh;
  height: 45vh;
}
}
@keyframes expandFontSize {
  0% {
    font-size: 2vw;
}
100% {
  font-size: 3.5vw;
}
}
@keyframes expandFontSizeB {
  0% {
    font-size: 2.5vw;
}
100% {
  font-size: 4.5vw;
}
}
.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  color: white;
}

.loading-screen img {
  max-width: 300px;
  margin-bottom: 2rem;
}

.loading-screen h2 {
  font-size: 1.5rem;
  text-align: center;
}
.no-award-message {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.itemAward{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 15vh;
  position: relative;
  border-radius: 30px;
  padding: 30px 30px;
  margin-right: 1vw;
  background: rgba(255, 255, 255, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  backdrop-filter: blur(10.5px);
  -webkit-backdrop-filter: blur(10.5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #fff;
}
.itemAward h2 {
  font-weight: bold;
}
.App.expanded #allRewardsWidget, .App.expanded .mainWidgetB {
  opacity: 0;
}

.winners-popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(5px);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 0.3s ease;
}

.winners-popup {
    background: white;
    border-radius: 15px;
    padding: 30px;
    width: 90%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    animation: popIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.winners-count {
    font-size: 0.8em;
    color: #666;
    margin-left: 10px;
    display: block;
}

.winner-item {
    opacity: 0;
    animation: slideIn 0.3s ease forwards;
    animation-delay: calc(var(--index) * 0.1s);
}

@keyframes popIn {
    0% {
        opacity: 0;
        transform: scale(0.8);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Estilos para los toasts */
.Toastify__toast {
    border-radius: 8px !important;
    font-family: inherit !important;
}

.Toastify__toast--success {
    background: #28a745 !important;
}

.Toastify__toast--error {
    background: #dc3545 !important;
}

.Toastify__toast--warning {
    background: #ffc107 !important;
    color: #000 !important;
}

.Toastify__toast--info {
    background: #17a2b8 !important;
}

.Toastify__toast-body {
    font-size: 14px;
    padding: 10px;
}

/* Asegurar que los toasts estén siempre visibles */
.Toastify__toast-container {
    z-index: 9999 !important;
}
.carousel.slide {
  height: 100%;
  width: 100%;
}
.carousel-control-prev, .carousel-control-next {
  display: none;
}
#winnerInfo {
  font-size: 2.5vw;
  transform: translate(0px, 10vh);
}
.winnerDepartmentValue, .winnerCodeValue {
  font-weight: bold;
  margin-left: 5px;
}
#appLogo {
  height: 50px;
  margin-top: 20px;
}
.spin-item {
    transition: all .1s ease-out;
    transform-origin: center center -50px;
    backface-visibility: hidden;
    position: relative;
}

@keyframes spinEffect {
    0% {
        transform: rotateX(0deg);
        opacity: 0.3;
    }
    50% {
        transform: rotateX(-20deg);
        opacity: 0.7;
    }
    100% {
        transform: rotateX(0deg);
        opacity: 1;
    }
}

.attendantList.spinning .spin-item {
  opacity: 0.5;
}
